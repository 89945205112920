/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Spin } from 'antd';
import dynamic from 'next/dynamic';
import { getResponsiveImage } from '@/componentWebs/NbmImageNew';
import { formatNumber } from '@/utils/utils';
import EncodeUrl from '@/utils/encode';
import { stringify } from 'qs';
import cookie from 'js-cookie';

// const { publicRuntimeConfig } = getConfig();
const OwlCarousel = dynamic(() => import('@/componentWebs/Global/OwlCarousel'), {
  ssr: false,
  loading: () => null,
});

@connect(({ webs: { dataAd } }) => ({
  dataAd,
}))
class Index extends Component {
  state = {
    dataIntroduce: [],
    dataTabsVisa: [],
    activeTab: '',
    loading: false,
  };

  componentDidMount() {
    const { data, dispatch } = this.props;
    dispatch({
      type: 'webs/fetchTravelVisaPackageGroups',
      payload: {
        filter: {
          status: '1',
        },
      },
      callback: res => {
        if (res.success) {
          if (res.result.list.length) {
            const dataTabsVisa =
              (res &&
                res.result &&
                res.result.list &&
                res.result.list.length > 0 &&
                res.result.list) ||
              [];
            this.setState({
              dataTabsVisa,
              activeTab:
                (dataTabsVisa &&
                  dataTabsVisa.length > 0 &&
                  dataTabsVisa[0] &&
                  dataTabsVisa[0].id) ||
                '',
            });
          }
        }
      },
    });
    dispatch({
      type: 'webs/fetchTravelVisa',
      payload: {
        filter: {
          status: '1',
          travelVisaPackagesStatus: '1',
          categoriesId: data.id,
          categoriesProductsCatalogStatus: '1',
        },
        // attributes: `id,status`,
      },
      callback: res => {
        if (res.success) {
          if (res.result && res.result.list && res.result.list.length > 0) {
            const newDataIntroduce = (res && res.result && res.result.list) || [];
            this.setState({
              dataIntroduce: newDataIntroduce,
            });
          }
        } else {
          this.setState({
            dataIntroduce: [],
          });
        }
      },
    });
  }

  handleClickDat = item => {
    // const totalPrice = this.handleTotoal();

    const newData = {
      travelVisaPackageName: item.travelVisaPackageName,
      dealPrice: item.travelVisaPackagesDealPrice,
      serviceCode: 'VISA',
    };

    // notification

    cookie.remove('saveDataBooking');
    const date = new Date();
    const minutes = 30;
    date.setTime(date.getTime() + minutes * 60 * 1000);
    cookie.set('saveDataBooking', newData, { expires: date });
    window.location.href = `/checkout?booking=VISA`;
  };

  render() {
    const { dataTabsVisa, dataIntroduce, activeTab, loading } = this.state;

    const { data } = this.props;
    const template =
      data.templateLayouts && data.templateLayouts.templates && data.templateLayouts.templates[0];
    const imageResize =
      template && template.templateLayoutTemplates && template.templateLayoutTemplates.imagesResize;
    const newDataIntroduce =
      (
        (dataIntroduce &&
          dataIntroduce.length > 0 &&
          dataIntroduce.map(item => {
            const travelVisaPackages =
              (item.travelVisaPackages &&
                item.travelVisaPackages.length > 0 &&
                item.travelVisaPackages.filter(
                  x =>
                    (x.travelVisaPackageGroups &&
                      x.travelVisaPackageGroups.id &&
                      Number(x.travelVisaPackageGroups.id)) === Number(activeTab) &&
                    Number(x.status) === 1
                )) ||
              [];
            return {
              id: item.id,
              countries: item.countries,
              travelVisaPackages,
            };
          })) ||
        []
      ).filter(item => item.travelVisaPackages && item.travelVisaPackages.length > 0) || [];
    // console.log(dataIntroduce, newDataIntroduce);
    return (
      // Tin tức trang chủ
      <React.Fragment>
        {/* Dịch vụ Visa nhanh chóng tiện lợi  */}
        <section className="visa">
          <div className="container">
            <div className="visa__wrapper">
              <div className="visa__header flex-b-c">
                <div
                  className="section-header-products height-auto-mobile"
                  style={{ borderBottom: 'unset' }}
                >
                  <h3 className="title">
                    <img
                      src="/static/vietnamTickets/images/005-passport.svg"
                      alt={data.name}
                      className="lazyload"
                      width="auto"
                      height="auto"
                    />
                    <span>{data.name}</span>
                  </h3>
                </div>
                <ul className="main-navigation navigation-visa hidden-m-t">
                  {dataTabsVisa &&
                    dataTabsVisa.length > 0 &&
                    dataTabsVisa.map(item => (
                      <li key={item.id} className={`tab ${item.id === activeTab && 'active'}`}>
                        <a
                          onClick={() => {
                            this.setState({
                              loading: true,
                            });
                            setTimeout(() => {
                              this.setState({
                                activeTab: item.id,
                                loading: false,
                              });
                            }, 200);
                          }}
                        >
                          <span>{item.travelVisaPackageGroupName}</span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="202.068"
                            viewBox="0 0 202.068 51.844"
                          >
                            <path
                              id="Path_4163"
                              data-name="Path 4163"
                              d="M23289.355-14934.914s16.607-.354,22.189-13.742,10.816-37.814,43.607-37.934,70.2,0,70.2,0,26.232-1.732,36.559,18.467,7.742,28.393,29.514,33.324C23492.418-14934.945,23289.355-14934.914,23289.355-14934.914Z"
                              transform="translate(-23289.355 14986.643)"
                              fill="#eb0281"
                            />
                          </svg>
                        </a>
                      </li>
                    ))}
                </ul>
              </div>

              <div className="visa__products">
                <Spin spinning={loading}>
                  {newDataIntroduce && newDataIntroduce.length > 0 && (
                    <OwlCarousel
                      className="carousel-star"
                      key={activeTab}
                      responsive={{
                        0: {
                          nav: false,
                          items: 1,
                          margin: 30,
                          dots: true,
                        },
                        425: {
                          nav: false,
                          items: 1,
                          margin: 30,
                          dots: true,
                        },
                        768: {
                          nav: false,
                          items: 2,
                          margin: 30,
                          dots: false,
                        },
                        1000: {
                          nav: false,
                          items: 2,
                          margin: 30,
                          dots: false,
                        },
                        1240: {
                          nav: false,
                          items: 3,
                          margin: 30,
                          dots: false,
                        },
                      }}
                      margin={30}
                      // autoplay
                      slideBy={1}
                    >
                      {newDataIntroduce.map(item => (
                        <div className="visa__box" key={item.id}>
                          <div className="visa__box-img">
                            <div
                              className="visa__box-bg-img"
                              style={{
                                backgroundImage: `url("${item.countries &&
                                  item.countries.images &&
                                  item.countries.images[0] &&
                                  item.countries.images[0].file &&
                                  getResponsiveImage(
                                    item.countries.images[0].file,
                                    imageResize
                                  )}")`,
                              }}
                            />
                            <div className="visa__box-img__text">
                              <div className="title">
                                {item.countries && item.countries.countrieName}
                              </div>
                              <a
                                onClick={() =>
                                (window.location.href = `/searchVisa?${stringify({
                                  diaDiem: [item.countries && item.countries.id],
                                })}`)
                                }
                              >
                                Xem tất cả
                              </a>
                            </div>
                          </div>
                          <div className="visa__box-body">
                            <ul className="visa__box-list">
                              {item.travelVisaPackages &&
                                item.travelVisaPackages.length > 0 &&
                                item.travelVisaPackages.slice(0, 3).map(item1 => (
                                  <li className="visa__box-item" key={item1.id}>
                                    <div className="visa__box-item__detail">
                                      <div
                                        className="visa__box-item__title"
                                        style={{ cursor: 'pointer' }}
                                        onClick={() =>
                                        (window.location.href = `/searchVisa?${stringify({
                                          diaDiem: [item.countries && item.countries.id],
                                        })}`)
                                        }
                                      >
                                        {item1.travelVisaPackageName}
                                      </div>
                                      <div className="visa__box-item__price">
                                        {formatNumber(item1.travelVisaPackagesDealPrice)}đ
                                      </div>
                                    </div>
                                    <a onClick={() => this.handleClickDat(item1)}>
                                      <span>Đặt ngay</span>
                                      <i className="fas fa-long-arrow-alt-right" />
                                    </a>
                                  </li>
                                ))}
                            </ul>
                          </div>
                        </div>
                      ))}
                    </OwlCarousel>
                  )}
                </Spin>
              </div>

              {/* <div className="pagiTuyenBay-top hidden-pc" /> */}
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Index;
